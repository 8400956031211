* {
  font-family: Bariol, sans-serif; }

@font-face {
  font-family: Bariol;
  src: url("/dist/fonts/Bariol_Regular.otf") format("opentype"); }

@font-face {
  font-family: Bariol;
  font-weight: bold;
  src: url("/dist/fonts/Bariol_Bold.otf") format("opentype"); }

html {
  height: 100%;
  width: 100%; }

body,
#react-app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  justify-content: center; }

.app-container {
  display: flex;
  flex-direction: column;
  width: 1200px; }

.standard-popover {
  border-style: solid;
  border-color: #fbfbfb;
  padding: 6px;
  border-radius: 10px;
  background-color: white; }

.hover-popover {
  max-height: 300px;
  overflow-y: scroll; }

.standard-dialog {
  max-height: 70vh;
  max-width: 70vw;
  max-height: 1000px;
  max-width: 800px; }

.standard-dialog-content {
  padding: 8px 16px 0;
  margin-bottom: 8px;
  overflow: scroll; }

.standard-select .bp3-menu {
  max-height: 190px;
  width: 300px;
  overflow: auto;
  /* Invert blueprintjs select dropdown menuitem visiblity:
    Default: text (left) has ellipsis if too long, label takes remaining space
    Now: text takes space, label has ellipsis if too long
    */ }
  .standard-select .bp3-menu .bp3-text-overflow-ellipsis.bp3-fill {
    overflow: unset; }
  .standard-select .bp3-menu .bp3-menu-item-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex-shrink: 1; }

.centered-content {
  display: flex;
  justify-content: center; }

/* Blueprint */
.bp3-card {
  margin: 8px 4px 0;
  padding: 16px; }

.bp3-html-table {
  padding-bottom: 10px; }

/* Fix issues where collapsibles create new stacking context, causing children to be unclickable
in tutorial */
.bp3-collapse-body {
  display: initial; }
