.download-files-dialog-content {
  padding: 8px 16px 0;
  margin-bottom: 8px;
  overflow: scroll; }

.download-file-callout {
  display: inline-block; }

.output-fields-selection-container {
  padding: 10px 0; }
