.variable-manager-table {
  display: block;
  overflow: scroll; }

.variable-manager {
  padding: 8px; }

.ignored-variables {
  margin-top: 10px; }

.variable-manager-table {
  max-height: 40vh; }
