.variable-manager {
  padding: 8px; }

.variable-section-header {
  display: flex;
  align-items: baseline; }
  .variable-section-header .ignored-variables {
    margin-top: 10px; }
  .variable-section-header .variable-helper {
    padding-right: 6px; }
