footer {
  margin-bottom: 16px; }

.footer-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  align-items: center; }
  .footer-container .footer-divider {
    margin: 0 10px;
    align-self: normal; }
  .footer-container h6 {
    margin: 0; }
