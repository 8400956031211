.debug-manager {
  top: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  background-color: white;
  border-style: ridge;
  padding: 8px;
  opacity: 0.6;
  transition: opacity 0.5s;
  max-width: 250px; }
  .debug-manager:hover {
    opacity: 1; }

.opaque {
  opacity: 1; }

.debug-mode-button {
  margin-bottom: 8px; }

.draggable-handle {
  background-color: lightgrey;
  cursor: all-scroll; }
