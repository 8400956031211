#tutorial-container {
  z-index: 26;
  position: fixed;
  background-color: white;
  margin: 0 auto;
  max-width: 1000px; }

.tutorial-status-modal {
  border-style: double; }

.tutorial-button-container button {
  margin: 8px 0; }

.tutorial-position-top {
  width: 80vw;
  top: 10px;
  left: 50%;
  transform: translateX(-50%); }

.tutorial-position-top-left {
  width: 45vw;
  top: 10px;
  left: 30px; }

.tutorial-grey-box, .tutorial-disabled-box {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.tutorial-grey-box, .tutorial-disabled-box {
  z-index: 24; }

.tutorial-active-container, .tutorial-stacking-container, .tutorial-active-enabled > *, .tutorial-active-disabled > * {
  position: relative; }

.tutorial-stacking-container {
  z-index: 25; }

.tutorial-grey-box {
  background-color: rgba(128, 128, 128, 0.7); }

.tutorial-stacking-grey-box {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.7); }

.tutorial-disabled-box {
  background-color: rgba(0, 0, 0, 0);
  z-index: 25; }

.tutorial-instructions-container {
  display: flex;
  justify-content: space-between; }

.tutorial-active-enabled > * {
  z-index: 26; }

.tutorial-active-disabled > * {
  z-index: 24; }

.continue-tutorial-button {
  margin-top: 20px; }

.tutorial-exit-button {
  white-space: nowrap; }

.tutorial-is-modal > * {
  z-index: 24; }
  .tutorial-is-modal > * .bp3-overlay-backdrop {
    background-color: transparent; }
