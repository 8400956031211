.collapsible-card {
  padding: 0; }

.collapsible-header {
  display: flex;
  cursor: pointer;
  padding: 4px 0 0 4px; }

.collapsible-body {
  padding: 8px 8px 2px 8px; }

.collapsible-header-open {
  background-color: rgba(230, 230, 230, 0.3); }

.summary-text {
  line-height: 23px;
  margin-left: 4px; }
