.welcome-header {
  display: flex;
  justify-content: space-between; }

.welcome-buttons {
  display: flex; }
  .welcome-buttons > div,
  .welcome-buttons > a {
    margin: 0 4px; }
  .welcome-buttons > a {
    margin-top: 6px; }
