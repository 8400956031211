.calculation-builder {
  display: flex; }
  .calculation-builder > :first-child {
    flex: 4;
    padding-right: 10px; }
  .calculation-builder > :last-child {
    flex: 1;
    padding-left: 10px; }

.calculation-options {
  background-color: #ebf1f5; }
  .calculation-options .collapsible-card:first-child {
    margin-top: 0; }
  .calculation-options .collapsible-card {
    margin: 4px 0; }
