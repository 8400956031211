.filter {
  display: flex;
  align-items: start;
  flex-direction: row;
  padding-bottom: 4px; }

.filter-variable-suggest {
  width: 80px; }

.filter-type-variable {
  padding: 0 8px;
  width: 180px; }
